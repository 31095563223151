<template>
  <section class="container-scroller">

    <div class="row">
      <div class="col-lg-12 grid-margin">
        <h3 class="card-title text-white rounded p-1 mb-1 text-center" style="background-color: #0B5D3F; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
          Liste des Patients
        </h3>
        <div class="card">

          <div class="card-body">
            <div class="input-group input-group-sm mb-3 w-100">
          <router-link to="/patients/create">
            <button class="btn btn-success btn-sm btn-medpay-green-small  " type="button">Nouveau patient</button> </router-link>
            &nbsp;&nbsp;
            <input @input="fetchPatient(q)" type="text" v-model="q" class="form-control rounded-pill py-3" placeholder="Rechercher un patient ..."
            aria-label="">
          </div>

            <div class="table-responsive" style="min-height:200px">
              <table id="order-listing" ref="myTable" class="table table-bordered table-hover">
                <thead>
                  <tr style="background-color: rgb(216, 218, 216)">
                    <th>#</th>
                    <th>IPP</th>
                    <th>Nom</th>
                    <th>Prénom(s)</th>
                    <th>Age</th>
                    <th>Téléphone</th>
                    <th>Adresse</th>
                    <th>Sexe</th>
                    <th class="text-center action-th">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(patient, index) in patients" :key="patient.uuid">
                    <td>{{ index + 1 }}</td>
                    <td>{{ patient.ipp }}</td>
                    <td style="text-transform: uppercase">
                      {{ patient.lastname }}
                    </td>
                    <td style="text-transform: capitalize">
                      {{ patient.firstname }}
                    </td>
                    <td>{{ patient.age }}</td>
                    <td>{{ patient.phone }}</td>
                    <td>{{ patient.maison }}</td>
                    <td>{{ patient.gender }}</td>

                    <td>
                      <div class="btn-group btn-group-sm" role="group">
                        <button
                          id="btnGroupDrop1"
                          type=""
                          style="padding: 0px 10px"
                          class="btn btn-light dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          &nbsp;
                          <i class="fa fa-tasks" aria-hidden="true"></i> &nbsp;
                        </button>
                        <div class="dropdown-menu action-button-div" aria-labelledby="btnGroupDrop1" style="box-shadow: 0 4px 6px rgb(0 0 0 / 30%)">
                          <a @click="detail(patient.uuid)" class="dropdown-item" style="cursor: pointer;">
                            <i class="mdi mdi-eye menu-icon"></i> Détail patient
                          </a>
                          <!-- <a class="dropdown-item" href="#">
                            <i class="fa fa-arrows-h" aria-hidden="true"></i> Historique des venues
                          </a> -->
                          <a class="dropdown-item" href="/payement/create">
                            <i class="fa fa-usd" aria-hidden="true"></i> &nbsp; Facture & Paiement
                          </a>

                          <hr class="m-1" />

                          <!-- <a class="dropdown-item" href="#">
                            <i class="fa fa-umbrella" aria-hidden="true"></i>Assurances
                          </a> -->
                          <a class="dropdown-item" href="#">
                            <i class="fa fa-folder-o" aria-hidden="true"></i>Dossier médical
                          </a>
                          <a class="dropdown-item" href="/movments/create">
                            <i class="fa fa-arrows-h" aria-hidden="true"></i>Créer une venue
                          </a>
                          <hr class="m-1" />
                          <a @click="update(patient.uuid)" size="sm" v-b-tooltip.hover title="Modifier"                         
                            class="dropdown-item" style="cursor: pointer;">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>Modifier                            
                          </a>                         
                        </div>
                      </div>                 
                    </td>
                  </tr>
                  <tr v-if="result"><td colspan="8"></td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import $ from "jquery";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.js"; // tres important pour le modal
import "../../../../node_modules/bootstrap/dist/js/bootstrap.min.js"; // tres important pour le modal
import { patientService } from "@/_services";
  // import { paysService } from "@/_services";

  export default {
    name: "patients",
    data() {
      return {
        patients: [],
        deleteIndex: null,
        deleteName: "",
        table: null,
        q:"",
        lastname: "",
        firstname: "",
        age: "",
        date_birth: "",
        maison: "",
        // pays_id: "",
        // departement_id: "",
        // commune_id: "",
        // arrondissement_id: "",
        // quartier_id: "",
        phone: "",
        email: "",
        whatsapp: "",
        profession: "",
        gender: "",
        emergency_contac: "",
        autre: "",
        ipp: "",
        // id: "",
        uuid: "",
        // nom, prenom, age, adresse, telephone, email, whatsapp, profession, sexe, urgencecontact, autre,
      };
    },
    mounted() {
      patientService.getPatient(this.uuid).then((res) => {
        this.patient = res.data.data;
        this.patient.uuid = this.uuid;
      });

      patientService.getAllPatients().then((res) => {
        this.patients = res.data.data;
      });
    },
    methods: {
      fetchPatient(request) {
        patientService.getSearchPatients(request).then((res) => {
          this.patients = res.data.data;
        });
      },
      
      detail(uuid) {
        this.$router.push("/patients/detail/" + uuid);
      },
      update(uuid) {
        this.$router.push("/patients/update/" + uuid);
      },
  
      supprimer(index) {
        this.deleteIndex = index;
        this.deleteName =
          this.patients[index].lastname + " " + this.patients[index].firstname;
        $("#confirmDeleteModal").modal("show");
      },

      confirmDelete() {
        const index = this.deleteIndex;
        const name = this.deleteName;

        if (index !== null) {
          patientService
            .deletePatient(this.patients[index].uuid)
            .then((res) => {
              this.patients.splice(index, 1);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.deleteIndex = null;
              this.deleteName = "";
              $("#confirmDeleteModal").modal("hide");
              this.showSuccessMessage();
            });
        }
      },

      showSuccessMessage() {
        toast.success("Patient supprimé avec succès !", {
          position: POSITION.TOP_RIGHT,
          timeout: 3000,
          bodyStyle: {
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontWeight: "bold",
          },
        });
      },
    },
  };
</script>

<style scoped>
@import "../../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
@import "../../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.css";
select {
  cursor: pointer;
  height: 43px !important;
}

input[type="radio"].demo6 {
  display: none;
}

input[type="radio"].demo6+label {
  position: relative;
  padding-left: 1.3rem;
}

input[type="radio"].demo6+label::before,
input[type="radio"].demo6+label::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  border-radius: 1rem;
}

input[type="radio"].demo6+label::before {
  bottom: 0;
  left: 0;
  border: 1px solid #ccc;
  background-color: #eee;
  width: 1rem;
  height: 1rem;
}

input[type="radio"].demo6+label::after {
  bottom: 3px;
  left: 3px;
  width: calc(1rem - 6px);
  height: calc(1rem - 6px);
}

input[type="radio"].demo6:checked+label::after {
  background-color: #45c28e;
}

input[type="checkbox"].demo2 {
  display: none;
}

input[type="checkbox"].demo2+label::before {
  content: "";
  border: 1px solid #45c28e;
  padding: 0 0.6rem;
  margin-right: 0.3rem;
}

input[type="checkbox"].demo2:checked+label::before {
  background-color: #45c28e;
}
</style>